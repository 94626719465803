@media (max-width:1200px) {
.container{ width:96%; margin:0 auto; padding:0;}
  .navbar-brand{padding: 5px; float: left; padding-left: 0px; padding-right:0px; padding-top: 0px; padding-bottom: 0px; height: 90%;}
  .gallery-items{ min-height: 205px; max-height: 205px;}
  .testi_box{ padding: 0 10p;}
  .blur_bg{ padding: 25px 20px;}
  .about_us p{ font-size: 14px;}
  .about_us span{ font-size: 15px;}
  .amenties ul li{ background-size: 76% auto;}
  .amt_box h2{ font-size: 16px;}
  .client_text .client_name{ font-size: 18px;}
  .client_text .client_postition{ font-size: 13px; letter-spacing: 1px;}
  .tourist_main ul li.right .place_cnt{ width: 64%;}
}

/************ Main **************/
@media (max-width: 990px) {
 .contact-txt .top_contact .social_icon{ margin-left: 80px;}
  .navbar-brand > img{ width: 100%; margin-left: 32px; margin-top: 10px;}
  .navbar-nav{ display: block;}
  .navbar-toggler{ display: none;}

  .about_us_img img{ width: 100%; height: auto;}
  .about_us{ margin-left: 0;}
  .amenties ul li{ width: 49%; background: none; border-right: 1px solid #cecece; border-bottom: 1px solid #cecece;}
  .amt_box{ background: url(../images/amt_bdr_left.jpg);}
  .amenties ul li:nth-child(2n){background: none; border-right: 0; margin-left: -4px;}
  .amenties ul li:last-child{ border: 0;}
  .amenties ul li:nth-last-child(2){ border-bottom: 0;}
  .gallery-items{ min-height: 156px; max-height: 156px;}
  .client_image{ width: auto; max-width: 70px} 
  .client_text{ margin-top: 12px;}
  .comment_box p{ font-size: 13px;}
  .contect_box{ position: inherit;  padding-top: 30px;}
  .carousel h3{ font-size: 40px;}
  .carousel p{ font-size: 24px;}
  .main_heading{ font-size: 26px; background-size: 50% auto; margin: 80px 0 0px 0;}
  .blogBox .item h2{ font-size: 20px;}
  .price_room{ font-size: 20px; margin-top: 10px;}
  .blogBox .item:hover .blogTxt h2{margin-top: 0px;}
  .blogBox .item:hover .price_room{ margin-bottom: 10px;}
  .blogBox .item:hover .blogTxt p{ padding-bottom: 10px}
  .blogBox .item .blogCategory a{ font-size: 12px;}
  #loadMore a{ padding: 5px 20px}
  .amenties{ padding: 0px 80px; margin-top: 30px;}
  .testi_box .comment_box p{ font-size: 14px;}
  .room_bg{ padding: 0px 0;}
  #loadMore{ padding-top: 15px;}
  .amenties_bg{ padding: 0px;}
  .amt_box{ padding: 0; height: 100px; background: transparent;}
  .gallery_bg{ padding: 0;}
  .blur_bg{ padding-top: 0;}
  .testi_box .testie_top{ padding-bottom: 10px}
  .comment_box{ margin-top: 10px; margin-bottom: 20px;}
  .amt_box img{ max-height: 50px;}
  .carousel-item{ height: auto;}
  
  
}
@media (max-width: 768px){

  h1.main_title{ font-size: 25px; padding-top: 15px; padding-bottom: 15px;}
  .d-none{ display: block !important;}
  .carousel p{ display: block !important;;}
  .blogBox .item p{ display: block !important;}
  .tourist_main ul{ padding: 0 15px;}

    .swiper-container {
        width: 100vw;
        height: 50vh;
        background: #000;
    }
  
}
@media (max-width: 767px) {
html { -webkit-text-size-adjust:none;}
.container{ width:93%; margin:0 auto; padding:0;}
.contact-txt{ display: none;}
.navbar-toggler{ display: block;background:url(../images/menu_icon.png) no-repeat center center;}
.navbar-brand > img{ width: 100%; margin-left: 0px; margin-top: 0px; max-width: 200px;}
.navbar-toggler span{ }
  .navbar{ float: right; text-align: right;}
  .logo_bg{ height: 65px;}
  .logo_bg::after{ border-top: 65px solid #de9130}
  .navbar-nav{ width: 300px; background: rgba(0,0,0,0.9)}
  .fixed-top{ max-height: 45px;}
  .navbar-collapse{ box-shadow: none;}
  .navbar-brand > img{ max-width: 150px; margin-top: 10px;}
  .amenties{ padding: 0;}
  .gallery-items{ width: 100%; min-height: inherit; max-height: inherit;}
  #carousel-example{ padding: 0 30px;}
  #carousel-example .carousel-indicators{ bottom: -5%;}
  .navbar-nav{ margin:8px -15px; position: fixed; width: 100%;min-width: inherit; left: 0;}
  ul.navbar-nav li{ margin: 0; border-bottom: 1px solid #ca953a;}
  .navbar-nav .nav-link{ padding: 8px 20px; }
.place_cnt{ margin-top: 0;}

.Button{
  background:url(../images/menu_icon.png) no-repeat center center;
  height: 30px;
  width: 30px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 0 0 0 0;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  display: inline;
  }
.carousel{ display: none;}

}

/************ iphone **************/
@media (max-width:600px) {
html { -webkit-text-size-adjust:none;}
.container{ width:91%;}
  .our_vision p{ padding: 10px 20px;}
  .contect_box{ padding: 20px 0 0 0;}
  .client_text{ width: 64%;}
  .client_image{ max-width: 58px; margin-top: 13px;}
  
  .testi_box .comment_box p{ font-size: 13px; width: 100%;}
  .tourist_main ul li .place{ width: 100%;}
  .place_cnt{ margin-top: 15px;}
  .tourist_main ul li.right .place{ width: 100%;}
  .tourist_main ul li.right .place_cnt, .place_cnt{ width: 100%; text-align: left; margin-bottom: 20px;}
  #carousel-example{ padding: 0 15px;}
  #image-gallery-1{ padding: 0 10px;}
  .carousel-item{  height: 30vh;}
  
 
}

@media (max-width:575px){
  .navbar-toggler{ margin-top: -68px; z-index: 9999;} 
  .navbar-nav{ margin-top: -12px;}
  .amenties ul{ padding-left: 0;}
  
}

