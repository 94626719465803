@charset "utf-8";
/* CSS Document */

body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

@font-face {
    font-family: 'kabel_bk_btbook';
    src: url('assets/fonts/kabeln-webfont.woff2') format('woff2'),
    url('assets/fonts/kabeln-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.fixed-top {
    background: rgba(0, 0, 0, 0.5);
    max-height: 85px;
}

.nav-link {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

.nav-link:hover {
    color: #de9130;
}

.contact-txt {
    display: block;
    text-align: right;
    width: 100%;
}

.contact-txt .top_contact {
    display: inline-block;
    margin: 0;
    padding: 7px 25px 7px 8px;
    border-bottom: 1px solid #de9130;
}

.contact-txt .top_contact .contct_box,
.contact-txt .top_contact .contct_box > a {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
}
.contact-txt .top_contact .contct_box > a:hover{
    text-decoration: none;
}
.contact-txt .top_contact .contct_box span {
    color: #de9130;
}

.contact-txt .top_contact .social_icon {
    display: inline-block;
    margin: 0 0 0 50px;
    padding: 0
}

.contact-txt .top_contact .social_icon a {
    display: inline-block;
    margin: 0 0 0 16px;
    padding: 0;
}

.contact-txt .top_contact .social_icon a i {
    font-size: 23px;
    color: #FFF;
}

.contact-txt .top_contact .social_icon a i:hover {
    color: #de9130;
}

.carousel-item {
    height: 100vh;
}

.navbar-nav {
    float: right;
}

ul.navbar-nav li a {
    padding: 0;
    margin: 0;
}

ul.navbar-nav li {
    margin: 0 15px;
}

.navbar {
    padding: 0;
    min-height: inherit;
    margin-top: 9px;
}

.carousel-item img {
    height: 650px;
    width: 100%;
}

.navbar-brand {
    height: inherit;
    padding: 9px 20px;
}

.carousel-control-next, .carousel-control-prev {
    width: auto;
    height: 25px;
    top: 50%;
}

.carousel-control-next {
    right: 2%;
}

.carousel-control-prev {
    left: 2%;
}

.carousel-indicators .active {
    margin: 4px 5px;
}

.carousel-indicators li {
    margin: 5px;
}

.logo_bg {
    float: left;
    background: #de9130;
    z-index: 9999;
    height: 95px;
    text-align: right;
}

.logo_bg::after {
    content: '';
    border-top: 95px solid #de9130;
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    height: 0;
    width: 40px;
    position: absolute;
    right: -40px;
}

.carousel-caption {
    top: 35%;
}

.carousel h3 {
    font-size: 80px;
    font-family: 'kabel_bk_btbook', sans-serif;
    letter-spacing: 5px;
}

.carousel p {
    font-size: 30px;
    font-family: 'kabel_bk_btbook', sans-serif;
    letter-spacing: 1px;
}

.main_heading {
    font-family: 'kabel_bk_btbook', sans-serif;
    letter-spacing: 5px;
    font-size: 50px;
    background: url(assets/images/heading_bdr.png) no-repeat bottom center;
    color: #de9130;
    text-transform: uppercase;
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 60px;
}

.about_us_img {
    display: block;
    margin: 30px 0 0 0;
    padding: 0;
}

.about_us {
    display: block;
    background: #FFF;
    margin: 0 0 0 -30px;
    padding: 20px 30px 30px 30px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
}

.about_us h2 {
    display: block;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: #de9130;
}

.about_us p {
    display: block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6e6e6e;
    margin: 20px 0 20px 0;
    padding: 0;
}

.about_us span {
    display: block;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #de9130;
    margin: 0;
    padding: 5px 0 7px 20px;
    font-style: italic;
    border-left: 3px solid #de9130;
}

.room_bg {
    background: url(assets/images/rom_bg.jpg) no-repeat left top;
    background-size: 100% 100%;
    padding: 60px 0;
    margin: 50px 0 0 0;
}

.blogBox {
    margin-bottom: 30px;
    box-sizing: border-box;
    position: relative;
}

.blogBox .item {
    background: #f4f4f4;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    height: 100%;
    position: relative;
}

.blogBox .item:hover {
    background: #e8e8e8;
    cursor: pointer;
}

.blogBox .item img {
    width: 100%;
    height: 100%;
}

.blogBox .item p {
    padding-bottom: 40px;
}

.blogBox .item .blogTxt {
    padding: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5)
}

.blogBox .item h2 {
    margin: 8px 0 18px 0;
    padding: 0 0 15px 0;
    font-family: 'Lato', sans-serif;
    font-size: 26px;
    font-weight: 400;
    color: #FFF;
    display: block;
    border-bottom: 2px solid #FFF;

}

.blogBox .item p {
    display: none;
}

.blogBox .item .blogCategory a {
    padding: 8px 12px;
    border: 1px solid #de9130;
    color: #de9130;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    display: none;
}

.blogBox .item .blogCategory a:hover {
    background: none;
    color: #fff;
    text-decoration: none;
    border: 1px solid #FFF;
}

.blogBox .item:hover .blogTxt p {
    display: block;
    color: #FFF;
    text-align: center;
}

.blogBox .item:hover .blogTxt {
    height: 100%;
}

.blogBox .item:hover .blogTxt h2 {
    margin-top: 40px;
}

.blogBox .item:hover .blogCategory {
    text-align: center;
}

.blogBox .item:hover .blogCategory a {
    display: inline-block;
    width: auto
}
.Button{
    display: none;
}

#loadMore {
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    width: 100%;
}

#loadMore button {
    background: none;
    color: #484848;
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid #484848;
    transition: all 0.25s ease-out;
    -webkit-font-smoothing: antialiased;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 1px;
}

#loadMore button:hover {
    background-color: #484848;
    color: #FFF;
    text-decoration: none;
    cursor: pointer;
}

.price_room {
    width: 100%;
    margin: 30px 0 40px 0;
    padding: 0;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 23px;
    font-weight: 400;
    color: #de9130;
    display: none;
}

.blogBox .item:hover .price_room {
    display: block;
}

.amenties_bg {
    background: url(assets/images/amenties_bg.jpg) left top;
    padding: 60px 0;
}

.amenties {
    display: block;
    width: auto;
    margin: 0;
    padding: 0;
}

.amenties ul li {
    display: inline-block;
    width: 24.7%;
    margin: 0;
    padding: 0 0 25px 0;
    vertical-align: bottom;
}

.amenties ul li:nth-child(-n+4){
    background: url(assets/images/amt_bdr_btm.jpg) no-repeat bottom center;
}

.amt_box{
    display: block;
    width: 100%;
    margin: 25px 0 0 0;
    padding: 25px 0 15px 0;
    text-align: center;
    height: 172px;
    vertical-align: top;
    background: url(assets/images/amt_bdr_left.jpg) no-repeat right center;
}

.amenties ul li:nth-child(4n) .amt_box{
   background: none;
}

.amt_box img {
    width: auto;
    max-height: 60px;
}

.amt_box h2 {
    text-align: center;
    width: auto;
    display: block;
    margin: 15px 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #cecece;
    text-transform: uppercase;
}

.nobg {
    background: none !important;
}

.imgs-grid {
    max-width: 100%;
    margin: 0 auto;
    font-size: 0;
}

.imgs-grid.imgs-grid-1 .imgs-grid-image {
    width: 100%;
    text-align: center;
}

.imgs-grid.imgs-grid-2 .imgs-grid-image,
.imgs-grid.imgs-grid-4 .imgs-grid-image {
    width: 50%;
}

.imgs-grid.imgs-grid-3 .imgs-grid-image,
.imgs-grid.imgs-grid-6 .imgs-grid-image {
    width: 25%;
}

.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(1),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(2),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(3) {
    width: 33.333333333333336%;
}

.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(4),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(5) {
    width: 50%;
}

.imgs-grid .imgs-grid-image {
    position: relative;
    display: inline-block;
    padding: 2px;
    box-sizing: border-box;
    text-align: center;
}

.imgs-grid .imgs-grid-image:before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: #f0f0f0;
}

.imgs-grid .imgs-grid-image:hover {
    cursor: pointer;
}

.imgs-grid .imgs-grid-image .image-wrap {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
}

.imgs-grid .imgs-grid-image .image-wrap img {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
}

.imgs-grid .imgs-grid-image .view-all {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}

.imgs-grid .imgs-grid-image .view-all:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}

.imgs-grid .imgs-grid-image .view-all:hover {
    cursor: pointer;
}

.imgs-grid .imgs-grid-image .view-all:hover .view-all-text {
    text-decoration: underline;
}

.imgs-grid .imgs-grid-image .view-all .view-all-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
}

.imgs-grid .imgs-grid-image .view-all .view-all-text {
    position: relative;
    font-size: 16px;
    font-family: sans-serif;
    color: white;
}

.testimonial_bg {
    background: url(assets/images/testimonial_bg.jpg) repeat-x left top;
    padding: 80px 0;
    background-size: cover;
}

.main_title {
    width: 100%;
    display: block;
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-weight: 400;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.black {
    color: #484848;
    background: url(assets/images/black_bdr.png) no-repeat bottom center;
}

.white {
    color: #cecece;
    background: url(assets/images/white_bdr.png) no-repeat bottom center;
}

.room_bg p.cnt_txt {
    text-align: center;
    padding: 0 15%;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #484848;
    letter-spacing: 1px;
    margin: 20px 0
}

.blur_bg {
    background: rgba(255, 255, 255, 0.5);
    padding: 50px 15px 70px 15px;
}

.testi_box {
    margin: 20px 0 10px 0;
    padding: 0;
    display: block;
}

.testi_box .testie_top {
    display: block;
    margin: 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #D9D9D9;
}

.client_image {
    display: inline-block;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    width: 10%;
    overflow: hidden;
    vertical-align: middle;
    max-width: 80px;
}

.client_image img {
    width: 100%;
    height: auto;
}

.client_text {
    display: inline-block;
    margin: 0 0 0 4%;
    padding: 0;
    width: 70%;
    vertical-align: middle;
}

.client_text .client_name {
    display: block;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 21px;
    font-weight: 400;
    font-style: italic;
    color: #484848;
}

.client_text .client_postition {
    display: block;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    color: #484848;
    line-height: 20px;
}

.comment_box {
    display: table;
    width: 100%;
    margin: 15px 0 0 0;
    padding: 0;
}

.comment_box i {
    font-size: 12px;
    display: table-cell;
    margin: 0;
    padding: 0;
    width: 8%;
    text-align: center;
    color: #666;
}

.comment_box i.fa-quote-right {
    vertical-align: bottom;
}

.comment_box p {
    display: table-cell;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: #484848;
    line-height: 20px;
    width: 80%;
    text-align: center;
}

.gallery_bg {
    background: url(assets/images/gallery__bg.jpg) no-repeat left top;
    padding: 50px 0;
    background-size: cover;
}

.contact_main {
    display: block;
    width: 100%;
    margin: 0;
    padding: 50px 0;
}

.map_box {
    display: block;
    width: auto;
    margin: 0;
    padding: 0;
    border: 8px solid #dddddd;
    height: auto;
}

.map_box iframe {
    width: 100%;
    height: 400px;
    display: block;
}

.contect_box {
    display: block;
    width: auto;
    margin: 0;
    padding: 0 60px;
    text-align: center;
    background: #FFF;
    position: absolute;
    top: 0;
}

.contect_box span {
    display: block;
    width: auto;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
}

.contect_box h1 {
}

.contect_box p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    display: block;
    width: auto;
    text-align: center;
    margin: 20px 0 30px 0;
    padding: 0;
    color: #484848;
}

.contect_box form {
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
}

.contect_box form .input_txt {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 15px;
    border: 1px solid #000;
    height: 40px;
    line-height: 40px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.contect_box form .area {
    height: 100px;
    line-height: 17px;
    padding: 8px 15px;
    resize: none;
}

.contect_box form .send_btn {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0 40px;
    border: 1px solid #000;
    height: 42px;
    line-height: 41px;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-weight: 400;
    color: #000;
    text-align: center;
    background: none;
}

.contect_box form .send_btn:hover {
    background: #000;
    color: #FFF;
}

.contect_box .social_icon_ftr {
    display: block;
    margin: 20px 0 10px 0;
    padding: 0;
}

.contect_box .social_icon_ftr i {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    font-size: 32px;
    color: #000;
    opacity: 0.7;
}

.contect_box .social_icon_ftr i:hover {
    opacity: 1;
}

.ftr_cnt {
    display: block;
    margin: 0;
    padding: 0;
    color: #FFF;
    text-align: left;
}

.ftr_cnt h3 {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 1px;
}

.ftr_cnt h3::after {
    content: '';
    border-bottom: 2px solid #FFF;
    width: 35px;
    display: block;
    margin-top: 5px;
}

.ftr_cnt ul {
    margin: 20px 0 0 -2px;
    padding: 0;
    display: block;
}

.ftr_cnt ul li {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
}

.ftr_cnt ul li i {
    font-size: 22px;
    display: table-cell;
    padding-top: 3px;
    padding-right: 15px;
    width: 40px;
    text-align: center;
}

.ftr_cnt ul li i.fa-envelope {
    font-size: 18px;
}

.ftr_cnt ul li p {
    display: table-cell;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 1px;
    vertical-align: top;
}

.ftr_cnt ul li p a {
    color: #FFF;
}

.ftr_cnt ul li p a:HOVER {
    text-decoration: none;
    color: #de9130
}

.ftr_cnt ul.quick_link > li {
    margin-bottom: 5px;
}

.ftr_cnt ul.quick_link > li a {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 10px;
}

.ftr_cnt ul.quick_link > li a:hover {
    text-decoration: none;
    color: #de9130
}

.bg-dark {
    background: #1e2529;
    border-top: 4px solid #de9130;
    padding-bottom: 30px;
}

.box_slide_control {
    display: none;
}

#carousel-example .carousel-indicators {
    bottom: -12%;
}

#carousel-example .carousel-indicators .active {
    background: #eecea7;
    border: none;
}

#carousel-example .carousel-indicators li {
    background: #606060;
    border: none;
}

.our_vision {
    background: #FFF;
    padding: 0;
    margin: 50px auto;
}

.our_vision p {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    display: block;
    width: auto;
    text-align: center;
    margin: 20px 0 30px 0;
    padding: 0 100px;
    color: #484848;
    border-left: 4px solid #de9130;
    border-right: 4px solid #de9130;
}

#myBtn {
    opacity: 0;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 9999;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #484848;
    color: white;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    box-shadow: 0 0 9px 3px #2f2f2f66;
}

#myBtn:hover {
    background-color: #555;
}

.tourist_main {
    display: block;
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;
}

.tourist_main ul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.tourist_main ul li {
    display: inline-block;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0;
    vertical-align: top;
}

.tourist_main ul li.right .place {
    float: right;
    width: 30%;
    margin: 0;
    padding: 0;
    max-height: 140px;
    overflow: hidden;
}

.tourist_main ul li .place {
    float: left;
    width: 30%;
    margin: 0;
    padding: 10px;
    /*max-height: 140px;*/
    overflow: hidden;
    text-align: center;
    background-color: #ffffff;
}

.tourist_main ul li .place img {
    /*width: 100%;*/
    /*height: auto;*/
    max-height: 300px;
    width: 100%;
}

.tourist_main ul li.right .place_cnt {
    text-align: right;
    margin-right: 10px;
    width: 65%;
}

.place_cnt {
    float: left;
    width: 66%;
    margin: 0 0 0 3%;
    padding: 0;
    /*max-height: 140px;*/
    vertical-align: top;
    margin-top: 10px;
}

.place_cnt h1 {
    display: block;
    /*margin: 0;*/
    /*padding: 0;*/
    font-size: 20px;
    color: #de9130;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.place_cnt p {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 20px;
    width: 100%;
    font-size: 15px;
    color: #484848;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.place_cnt p a {
    color: #de9130
}

.place_cnt p a:visited {
    color: #0254EB
}

.place_cnt p a.morelink {
    text-decoration: none;
    outline: none;
    color: #de9130;
}

.place_cnt p .morecontent span {
    display: none;
}

.place_cnt p .comment {
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px;
}

@media screen and (min-width: 1200px) {
    .blogBox .featured h2 {
        font-size: 42px;
    }
}

@media screen and (min-width: 991px) {
    .blogBox .featured h2 {
        font-size: 30px;
        font-style: italic;
    }

    .blogBox .featured .blogTxt {
        max-width: 50%;
        width: 100%;
        padding: 50px;
        float: left;
        background: inherit;
        min-height: 378px;
    }

    .blogBox .featured img {
        max-width: 50%;
        width: 100%;
        float: left;
        min-height: 378px;
    }
}

@media screen and (min-width: 768px) {
    /*.blogBox .item img {
        height: 152px;
    }*/
}

@media (max-width: 350px) {
    .imgs-grid .imgs-grid-image .view-all .view-all-text {
        font-size: 10px;
    }
}

.imgs-grid-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    opacity: 0;
    z-index: 100;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.imgs-grid-modal .modal-caption {
    padding: 30px 50px;
    text-align: center;
    color: white;
}

.imgs-grid-modal .modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    /*background-image: url(img/imgs-grid-icons.png);*/
    background-repeat: no-repeat;
    background-position: -100px;
}

.imgs-grid-modal .modal-close:hover {
    cursor: pointer;
}

.imgs-grid-modal .modal-inner {
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0;
    right: 0;
}

.imgs-grid-modal .modal-inner .modal-control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 70px;
}

.imgs-grid-modal .modal-inner .modal-control:hover {
    cursor: pointer;
}

.imgs-grid-modal .modal-inner .modal-control.left {
    left: 0;
}

.imgs-grid-modal .modal-inner .modal-control.right {
    right: 0;
}

.imgs-grid-modal .modal-inner .modal-control .arrow {
    margin: 0 auto;
    height: 100%;
    width: 40px;
    background-repeat: no-repeat;
    /*background-image: url(img/imgs-grid-icons.png);*/
}

.imgs-grid-modal .modal-inner .modal-control .arrow.left {
    background-position: 2px center;
}

.imgs-grid-modal .modal-inner .modal-control .arrow.right {
    background-position: -42px center;
}

.imgs-grid-modal .modal-inner .modal-image {
    position: absolute;
    top: 0;
    left: 70px;
    right: 70px;
    bottom: 0;
    text-align: center;
}

.imgs-grid-modal .modal-inner .modal-image:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}

.imgs-grid-modal .modal-inner .modal-image img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

.imgs-grid-modal .modal-inner .modal-image img:hover {
    cursor: pointer;
}

.imgs-grid-modal .modal-inner .modal-loader {
    display: inline-block;
    vertical-align: middle;
    color: silver;
    font-size: 14px;
}

@media (max-width: 800px) {
    .imgs-grid-modal .modal-inner .modal-control {
        width: 40px;
    }

    .imgs-grid-modal .modal-inner .modal-control .arrow {
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -o-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
    }

    .imgs-grid-modal .modal-inner .modal-image {
        left: 0;
        right: 0;
    }
}

.imgs-grid-modal .modal-indicator {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    text-align: center;
}

.imgs-grid-modal .modal-indicator ul {
    margin: 0;
    padding: 0;
}

.imgs-grid-modal .modal-indicator ul li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 100%;
    margin: 0 1px;
    vertical-align: middle;
}

.imgs-grid-modal .modal-indicator ul li:hover {
    cursor: pointer;
}

.imgs-grid-modal .modal-indicator ul li.selected {
    background-color: white;
    width: 14px;
    height: 14px;
    margin: 0;
}

.navbar-brand{
    margin-right: 0;
}

.rooms .blogBox{
    display: none;
}

.tourist_main .points{
    list-style: circle;
    font-size: 14px;
    margin: 20px;
}
.tourist_main .points > li {
    display: list-item !important;
    margin: 0;
}
.tourist_main .action {

}
.m-contact a {
    color: #fff;

}
.m-contact {
    color: #FFFFFF;
}

.swiper-container {
    width: 100vw;
    height: 100vh;
    background: #000;
}
.swiper-slide {
    font-size: 18px;
    color:#fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
}
.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}
.swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
}
.swiper-slide .subtitle {
    font-size: 21px;
}
.swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
}
.swiper-slide img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    background-image: url("assets/images/right.svg");
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    background-image: url("assets/images/left.svg");
}
.swiper-pagination-bullet-active{
    background-color: #FFF;
}

.hidden {
    display: none;
}

.footer-about-us{
    font-size: 12px;
    font-weight: normal;
    font-family: sans-serif;
}
.bg-black{
    background-color: #000000;
}
.copy-right p{
    margin: 0;
    font-size: 0.8em;
    font-family: 'Lato', sans-serif;
    color: white;
    padding: 10px 0px;
}
.copy-right a {
    color: #808080;
}
.copy-right p.siteKeywords {
    font-size: 0.7em;
    color: #545454;
}
.top-line{
    border-top: 1px solid #de9130;
}
